import React, { Component } from 'react';
import './Work.css';

class Work extends Component {
    render() {
        return (
            <div className="work">
                <div className="showcase-wrapper">
                    <h2>Charlie og sjokoladefabrikken - på Det Norske Teatret!</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315" title="Charlie og sjokoladefabrikken - på Det Norske Teatret!" src="https://www.youtube.com/embed/QvmyoNmOXz0?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </div>
                    <div>
                        <p>Musikk arrangert og produsert til teaser-filmen for Charlie og sjokoladefabrikken.</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>TEDx Arendal 2017</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315" title="TEDx Arendal 2017" src="https://www.youtube.com/embed/Mn6l6ZJMkOc?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </div>
                    <div>
                        <p>Musikk komponert og produsert for åpnings- og avslutningsfilm for TEDx Arendal 2017.</p>
                        <p>Klipp: Morten Krogstad</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>VIND JEWELRY</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315" title="TEDx Arendal 2017" src="https://www.youtube.com/embed/J4NuQsh1_40?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </div>
                    <div>
                        <p>Musikk komponert og produsert for Vind Jewelry.</p>
                        <p>Klipp: Mikkel Walle</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>Manchester United SCS</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315" title="Manchester united scs jingle" src="https://www.youtube.com/embed/Bhn_B8u8DBY?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </div>
                    <div>
                        <p>Jinglepakke komponert og produsert for Manchester United SCS</p>
                        <p>Grafikk: Sindre Grimsrud</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>Gullspiren 2016</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315"
                            title="Gullspiren 2016" src="https://www.youtube.com/embed/k2AIe5o5H4Y?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
                    </div>
                    <div>
                        <p>Musikk komponert og produsert for utdeling av årets Gullspire på Høyskolen Kristiania.</p>
                        <p>Grafikk: Kristoffer Stokkerud</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>TEDx Arendal 2016</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315"
                            title="TEDx Arendal 2016" src="https://www.youtube.com/embed/i2odCKrInoM?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
                    </div>
                    <div>
                        <p>Musikk komponert og produsert for aftermovie for TEDx Arendal 2016.</p>
                        <p>Klipp: Morten Krogstad</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>Larvik kommune</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315"
                            title="Larvik kommune" src="https://www.youtube.com/embed/c0vaETL3C4U?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
                    </div>
                    <div>
                        <p>Produksjon av musikk for Larvik Kommune.</p>
                        <p>Komposisjon: Peder Lundh</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>NOTAR</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315"
                            title="Notar" src="https://www.youtube.com/embed/JPE93tX9oMY?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
                    </div>
                    <div>
                        <p>Musikk komponert og produsert for jinglepakke til Notar AS.</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>TØNSBERG BÅTRACE</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315"
                            title="Tønsberg båtrace" src="https://www.youtube.com/embed/KVdGAa8_63g?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
                    </div>
                    <div>
                        <p>Musikk komponert og produsert for Tønsberg Båtrace 2017.</p>
                        <p>Klipp: Jon Christen Bjone</p>
                    </div>
                </div>

                <div className="showcase-wrapper">
                    <h2>DREAMS</h2>
                    <div className="video-wrapper">
                        <iframe width="560" height="315"
                            title="Dreams" src="https://www.youtube.com/embed/n2WMCWyV-qg?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </div>
                    <div>
                        <p>VoiceOver produsert for spareappen Dreams.</p>
                    </div>
                </div>

            </div>
        );
    }
}

export default Work;