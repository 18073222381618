import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// Components
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import Work from '../Work/Work';
import About from '../About/About';
import Contact from '../Contact/Contact';
// Styles
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="app">
        <Header />
        <div className="main-content">
          <Route path="/" exact render={() => <Home />} />
          <Route path="/work" exact render={() => <Work />} />
          {/* <Route path="/about" exact render={() => <About />} /> */}
          <Route path="/contact" exact render={() => <Contact />} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
