import React, { Component } from 'react';
import './Contact.css';
import Map from '../Map/Map';
import LazyLoad from 'react-lazyload';
import photo from '../../assets/portrait.jpg';

class Contact extends Component {
    render() {
        return (
            <div className="contact">
            <div className="media-object--contact">
                <div className="contact-info">
                    <p className="text">Ole Herman Andersen</p>
                    <p className="text"><a href="tel:+47 452 27 932">+47 452 27 932</a></p>
                    <p className="text"><a href="mailto:oleherman@OHDIO.no">oleherman@OHDIO.no</a></p>
                    <p>Christian Krohgs gate 2, 0186 Oslo</p>
                </div>
                <LazyLoad height='100%' once>
                    <img src={photo} className="portrait" alt="Ole Herman Andersen" />
                </LazyLoad>
            </div>
                <Map
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={< div style={{ height: `100%` }} />}
                    containerElement={< div style={{ height: `300px`, marginBottom: `40px` }} />}
                    mapElement={< div style={{ height: `100%`, filter: `grayscale(100%)` }} />}
            />
            </div>
        );
    }
}

export default Contact;