import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/logo.jpg';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <a className="header-link" href='/'>
                    <img className="logo" src={logo} alt="Logo" />
                </a>
                <ul className="nav">
                    <li>
                        <Link className="link" to={{pathname: '/work'}}>
                            <span className="link-text">Arbeid</span>
                        </Link>
                    </li>
                    {/* <li>
                        <Link className="link" to={{ pathname: '/about' }}>
                            <span className="link-text">Om</span>
                        </Link>
                    </li> */}
                    <li>
                        <Link className="link" to={{ pathname: '/contact' }}>
                            <span className="link-text">Kontakt</span>
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Header;