import React, { Component } from 'react';
import './About.css';
import LazyLoad from 'react-lazyload';
// Images
import studio from '../../assets/stohdio-landscape.jpg';
import studio2 from '../../assets/stohdio-2.jpg';
import snow from '../../assets/snow.jpg';
import gitar from '../../assets/gitar.jpg';

class About extends Component {
    render() {
        return (
            <div className="about">
                <div className="media-object">
                    <LazyLoad height='100%' offset={100}>
                        <img src={studio} className="landscape-image" alt="Studio landscape" />
                    </LazyLoad>
                    <div className="media-object-text">
                        <p>OHDIO Music komponerer og produserer musikk og lyddesign til film, reklame, underholdning, event og til direktekunder.</p>
                            
                        <p className="margin-bottom">Vi leverer også tjenester som gjennomgang av lydfiler, podkast og voiceover-produksjon. Har du et behov, finner vi den beste løsningen.</p>
                        <div className="image-collage">
                        <LazyLoad height='100%'>
                            <img src={snow} alt="Recording snow outside" />
                        </LazyLoad>
                        <LazyLoad height='100%' offset={100}>
                            <img src={gitar} alt="Playing guitar" />
                        </LazyLoad>
                        </div>
                        <p>OHDIO er del av et nettverk med flinke og dyktige fotografer, animatører og filmfotografer. Vi hjelper gjerne med å sette sammen et team for å dekke alle dine behov.</p>

                        <p>OHDIO har studio i Oslo sentrum, og er drevet av Ole Herman Andersen, musikkprodusent med mange års erfaring innen musikk og lydproduksjon.</p> 

                        <p>OHDIO har alltid som mål at kunden skal bli fornøyd. Lyden og opplevelsen skal være i høy kvalitet, uansett hvor den høres. Kom gjerne innom for å ta en prat og en kopp kaffe. </p>
                    </div>
                    <LazyLoad height='100%' offset={100}>
                        <img src={studio2} className="landscape-image" alt="Person playing keyboard" />
                    </LazyLoad>
                </div>
            </div>
        );
    }
}

export default About;