import React, { Component } from 'react';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div className="home">
                <div className="showreel">
                    <iframe id="ytplayer" type="text/html" width="560" height="315"
                        title="Showreel"
                        src="https://www.youtube.com/embed/6klMcuo6tJs?autoplay=1&controls=0&loop=1&playsinline=1&rel=0&amp;&amp;showinfo=0"
                        frameBorder="0" allow="autoplay" allowFullScreen></iframe>
                </div>
                <div className="text-wrapper">
                    <p>Skreddersydd musikk til aktører innen film, reklame, podkast, underholding og event- bransjen.</p>
                    {/* <div className="soundcloud-player">
                        <iframe width="300" height="300" scrolling="no" frameBorder="no"
                            title="soundclound player" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/258566654&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                    </div> */}

                </div>

            </div>
        );
    }
}

export default Home;