import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <a href="https://www.facebook.com/OHDIOMusic/?ref=br_rs" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-square"></i>
                </a>

                <a href="https://www.youtube.com/channel/UC_w1cC26b8TDcRSgNhGfGhw" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"></i>
                </a>

                <a href="https://soundcloud.com/user-889088830" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-soundcloud"></i>
                </a>

                <a href="https://www.instagram.com/explore/locations/868504313293038/OHDIO-music/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                </a>
            </div>
        );
    }
}

export default Footer;