import React from 'react'
import { render } from 'react-dom';
import { Router, Route } from 'react-router-dom';
// Components
import App from './components/App/App';
import registerServiceWorker from './registerServiceWorker';
import { createBrowserHistory } from 'history';

let history = createBrowserHistory();
let target = document.getElementById('root');

render(
    <Router history={history}>
        <Route component={App} />
    </Router>,
    target
);

registerServiceWorker();
