
import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Map = withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: 59.914117, lng: 10.757417 }}
    >
        {props.isMarkerShown && <Marker position={{ lat: 59.914117, lng: 10.757417 }} />}
    </GoogleMap>
)

export default Map;
